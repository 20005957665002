<template>
  <div>
    <ValidationObserver @submit="addAssignmentType">
      <UiModalContainer
        footer-classes="flex justify-end gap-3"
        :modal-show="modal"
        :modal-width="50"
        @handleClick="emit('toggle')"
      >
        <template v-slot:header>
          <span>
            {{ modalTitle }}
          </span>
        </template>
        <div>
          <div class="py-6 px-6">
            <!-- Main form fields -->
            <div class="flex flex-col">
              <div class="flex flex-col sm:flex-row gap-4 lg:gap-5">
                <InputBox
                  v-model.trim="assignmentData.title"
                  type="text"
                  name="TITLE"
                  title="TITLE"
                  label="TITLE"
                  placeholder="TITLE"
                  class="flex-1 w-full"
                  rules="required|alpha_numeric_spaces"
                />
                <UiSingleSelect
                  v-model="assignmentData.assignment_type_id"
                  title="ASSIGNMENT_TYPES"
                  :options="assignmentTypes"
                  label="title"
                  reduce="id"
                  class="flex-1"
                  rules="required"
                />
              </div>
              <div class="flex flex-col sm:flex-row gap-4 lg:gap-5">
                <UiSingleSelect
                  v-model="assignmentData.subject_id"
                  title="SUBJECT"
                  :options="subjectsList"
                  label="title"
                  reduce="id"
                  class="flex-1"
                  rules="required"
                  @change="getChaptersListHandler(assignmentData.subject_id)"
                  @deSelect="getChaptersListHandler(assignmentData.subject_id)"
                />
                <UiSingleSelect
                  v-model="assignmentData.chapter_id"
                  title="Chapter"
                  :options="chaptersList"
                  label="title"
                  reduce="id"
                  class="flex-1"
                  @change="getTopicsListHandler(assignmentData.chapter_id)"
                  @deSelect="getTopicsListHandler(assignmentData.chapter_id)"
                />
              </div>
              <div class="flex flex-col sm:flex-row gap-4 lg:gap-5">
                <UiSingleSelect
                  v-model="assignmentData.topic_id"
                  title="TOPIC"
                  :options="topicsList"
                  label="title"
                  reduce="id"
                  class="flex-1"
                  @change="getLessonsListHandler(assignmentData.topic_id)"
                  @deSelect="getLessonsListHandler(assignmentData.topic_id)"
                />
                <UiSingleSelect
                  v-model="assignmentData.lesson_id"
                  title="LESSONS"
                  :options="lessonsList"
                  label="title"
                  reduce="id"
                  class="flex-1"
                />
              </div>
              <div class="flex flex-col sm:flex-row gap-4 lg:gap-5">
                <InputBox
                  v-model="assignmentData.marks"
                  type="text"
                  name="MARKS"
                  title="MARKS"
                  label="MARKS"
                  placeholder="MARKS"
                  class="flex-1 w-full"
                  rules="required|numeric"
                />
                <InputBox
                  v-model="assignmentData.description"
                  type="text"
                  name="Description"
                  title="Description"
                  label="Description"
                  placeholder="Description"
                  class="flex-1 w-full"
                />
              </div>
            </div>
            <!-- Web Links -Section-->
            <Divider />
            <WebLinksSection v-model="webLinks" />
            <!-- Check Lists Section-->
            <Divider />
            <CheckListsSection v-model="checkListItems" />
            <!-- Assignment Attachments Section -->
            <Divider />
            <AttachmentsSection v-model="attachments" />
            <!-- Assignment Settings Section -->
            <Divider />
            <AssignmentSettingsSection v-model="settings" />
          </div>
        </div>
        <template v-slot:footer>
          <button
            class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button-outline"
            @click="emit('toggle')"
          >
            Cancel
          </button>
          <button
            class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button"
          >
            <span v-if="isLoading">
              <Loader />
            </span>
            <span v-else>
              {{ buttonText }}
            </span>
          </button>
        </template>
      </UiModalContainer>
    </ValidationObserver>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'

// Components
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { Form as ValidationObserver } from 'vee-validate'
import Divider from '@src/components/Divider.vue'
import WebLinksSection from '@src/router/views/assignments/modals/WebLinksSection.vue'
import CheckListsSection from '@src/router/views/assignments/modals/CheckListsSection.vue'
import AttachmentsSection from '@src/router/views/assignments/modals/AttachmentsSection.vue'
import AssignmentSettingsSection from '@src/router/views/assignments/modals/AssignmentSettingsSection.vue'

// Own Modules
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { buildWhereQuery } from '@utils/filters/index'
import { transformAssignmentData } from '@src/router/views/assignments/utils/index.js'

// Store
import { useStore } from 'vuex'
const store = useStore()

// Props
const { edit, modal } = defineProps({
  modal: {
    type: Boolean,
    default: false,
  },
  edit: {
    type: Object,
    default: () => {},
  },
})

// Emits
const emit = defineEmits(['toggle'])

// States
const isLoading = ref(false)
const assignmentData = ref({
  title: '',
  description: '',
  assignment_type_id: '',
  subject_id: '',
  chapter_id: '',
  topic_id: '',
  lesson_id: '',
  due_date: '',
  marks: '',
  assignment_attachments: [],
  assignment_web_links: [],
  assignment_checklists: [],
  assignment_submission_setting: {},
})
const webLinks = ref([])
const checkListItems = ref([])
const settings = ref([])
const subjectsList = ref([])
const chaptersList = ref([])
const topicsList = ref([])
const lessonsList = ref([])
const assignmentTypes = ref([])
const attachments = ref([])

// Computed

const modalTitle = computed(() => {
  return !edit ? 'Add Assignment' : 'Edit Assignment'
})

const buttonText = computed(() => {
  return edit ? 'Update' : 'Save'
})

// Mounted
onMounted(() => {
  getSubjectsListHandler()
  getAssignmentTypesHandler()
  if (edit) setAssignmentDataHandler()
})
// Methods
const addAssignmentType = () => {
  isLoading.value = true
  const data = { assignment: assignmentDataHandler() }
  if (edit) data.id = edit?.id
  addAndEditAssignments(data)
    .then(() => {
      let message = `${assignmentData.value.title} ${edit?.id ? 'updated' : 'added'} Successfully `
      store.commit('toast/NEW', { message: message, type: 'success' })
      emit('toggle', 'fetch')
    })
    .finally(() => {
      isLoading.value = false
    })
}

const assignmentDataHandler = () => {
  return {
    ...assignmentData.value,
    assignment_web_links: webLinks.value,
    assignment_checklists: checkListItems.value,
    assignment_submission_setting: settings.value,
    assignment_attachments: attachments.value,
  }
}

const setAssignmentDataHandler = () => {
  assignmentData.value = transformAssignmentData(edit)

  webLinks.value = edit?.assignment_web_links
  checkListItems.value = edit?.assignment_checklists
  attachments.value = edit?.assignment_attachments
  settings.value = edit?.assignment_submission_setting

  if (assignmentData.value.subject_id)
    getChaptersListHandler(assignmentData.value.subject_id, 'fetch')
  if (assignmentData.value.chapter_id)
    getTopicsListHandler(assignmentData.value.chapter_id, 'fetch')
  if (assignmentData.value.topic_id) getLessonsListHandler(assignmentData.value.topic_id, 'fetch')
}

const getSubjectsListHandler = () => {
  getSubjectList().then((res) => {
    subjectsList.value = res?.data?.records
  })
}

const getChaptersListHandler = (subjectID, status) => {
  if (!status) {
    assignmentData.value.chapter_id = ''
    assignmentData.value.topic_id = ''
    assignmentData.value.lesson_id = ''
  }
  chaptersList.value = []
  topicsList.value = []
  lessonsList.value = []

  let payload = {
    $where: {
      ...buildWhereQuery(FILTER_KEYS.EQUAL, 'subject_id', subjectID),
    },
  }

  getChaptersList(payload).then((res) => {
    chaptersList.value = res.records
  })
}

const getTopicsListHandler = (chapterID, status) => {
  if (!status) {
    assignmentData.value.topic_id = ''
    assignmentData.value.lesson_id = ''
  }
  topicsList.value = []
  lessonsList.value = []

  let payload = {
    $where: {
      ...buildWhereQuery(FILTER_KEYS.EQUAL, 'subject_id', assignmentData.value.subject_id),
      ...buildWhereQuery(FILTER_KEYS.EQUAL, 'chapter_id', chapterID),
    },
  }
  getTopicsList(payload).then((res) => {
    topicsList.value = res.records
  })
}
const getLessonsListHandler = (topicId, status) => {
  if (!status) {
    assignmentData.value.lesson_id = ''
  }
  lessonsList.value = []

  let payload = {
    $where: {
      ...buildWhereQuery(FILTER_KEYS.EQUAL, 'topic_id', topicId),
    },
  }

  getLessonList(payload).then((res) => {
    lessonsList.value = res.records
  })
}

const getAssignmentTypesHandler = (range) => {
  getAssignmentTypes().then((response) => {
    assignmentTypes.value = response?.data?.records
  })
}

const addAndEditAssignments = (data) => store.dispatch('assignment/addAndEditAssignments', data)
const getSubjectList = (payload) => store.dispatch('subjects/classSubjects', payload)
const getChaptersList = (payload) => store.dispatch('chapter/getChapters', payload)
const getTopicsList = (payload) => store.dispatch('chapter/getTopics', payload)
const getLessonList = (payload) => store.dispatch('lesson/getLessons', payload)
const getAssignmentTypes = (payload) => store.dispatch('assignment/getAssignmentTypes', payload)
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
