<template>
  <div>
    <Heading heading="Assignment Submission Settings" />
    <!-- Assignment Resubmission -->
    <div class="flex justify-between items-center">
      <Heading heading="Assignment Resubmission" heading-size="text-base" />
      <ToggleButton v-model="settings.assignment_resubmission_allowed" :is-status-hide="true" />
    </div>
    <!-- Submission Description Required -->
    <div class="flex justify-between items-center">
      <Heading heading="Submission Description Required" heading-size="text-base" />
      <ToggleButton v-model="settings.submission_description_required" :is-status-hide="true" />
    </div>
    <!-- Assignment Required -->
    <div class="flex justify-between items-center">
      <Heading heading="Assignment Acceptance" heading-size="text-base" />
      <div class="flex items-center gap-2">
        <UiCheckBox
          name="Single"
          :model-value="assignmentRequiredOption === 'single'"
          label="Single"
          type="radio"
          value="single"
          @change="updateassignmentRequiredOptionStatus"
        />
        <UiCheckBox
          name="Multiple"
          label="Multiple"
          type="radio"
          :model-value="assignmentRequiredOption === 'multiple'"
          value="multiple"
          @change="updateassignmentRequiredOptionStatus"
        />
        <UiCheckBox
          name="One to Multiple"
          label="One to Multiple"
          type="radio"
          :model-value="assignmentRequiredOption === 'one_to_multiple'"
          value="one_to_multiple"
          @change="updateassignmentRequiredOptionStatus"
        />
      </div>
    </div>
    <!-- Attachment File Type -->
    <div class="flex justify-between items-center">
      <Heading heading="Attachment File Type" heading-size="text-base" />
      <div class="flex items-center gap-2">
        <UiCheckBox
          :model-value="assignmentTypes.includes('Zip')"
          name="Zip"
          label="Zip"
          value="Zip"
          @change="updateAttachmentType"
        />
        <UiCheckBox
          :model-value="assignmentTypes.includes('PDF')"
          name="PDF"
          label="PDF"
          value="PDF"
          @change="updateAttachmentType"
        />
        <UiCheckBox
          :model-value="assignmentTypes.includes('Docx/GDoc')"
          name="Docx/GDoc"
          label="Docx/GDoc"
          value="Docx/GDoc"
          @change="updateAttachmentType"
        />
        <UiCheckBox
          :model-value="assignmentTypes.includes('JPG/PNG')"
          name="JPG/PNG"
          label="JPG/PNG"
          value="JPG/PNG"
          @change="updateAttachmentType"
        />
        <UiCheckBox
          :model-value="assignmentTypes.includes('Custom')"
          name="Custom"
          label="Custom"
          value="Custom"
          @change="updateAttachmentType"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, reactive } from 'vue'

// Components
import Heading from '@src/components/Heading.vue'
import ToggleButton from '@src/components/BaseComponent/toggleButton.vue'
import UiCheckBox from '@src/components/UiElements/UiCheckbox.vue'

const props = defineProps({ modelValue: { default: () => {}, type: Object } })

const emits = defineEmits(['update:modelValue'])

// States
let settings = reactive({
  assignment_resubmission_allowed: false,
  attachment_required: 'single',
  attachment_file_type: [],
  submission_description_required: false,
})
let assignmentRequiredOption = ref('single')
let assignmentTypes = ref([])

// watch

watch(
  settings,
  (value) => {
    emits('update:modelValue', value)
  },
  { immediate: true },
)

watch(
  () => props.modelValue,
  (newValue) => {
    settings = newValue
    assignmentRequiredOption.value = newValue.attachment_required
    assignmentTypes.value = newValue.attachment_file_type
    emits('update:modelValue', settings)
  },
)

const updateassignmentRequiredOptionStatus = (ev) => {
  if (ev.status) {
    assignmentRequiredOption.value = ev.value
    settings.attachment_required = ev.value
  }
}
const updateAttachmentType = (ev) => {
  if (ev.status) assignmentTypes.value.push(ev.value)
  else {
    let index = assignmentTypes.value.indexOf(ev.value)
    if (index !== -1) assignmentTypes.value.splice(index, 1)
  }
  settings.attachment_file_type = assignmentTypes.value
}
</script>
