export const transformAssignmentData = (edit) => {
  return {
    title: edit?.title || '',
    description: edit?.description || '',
    assignment_type_id: edit?.assignment_type_id,
    marks: edit?.marks,
    subject_id: edit?.subject_id,
    chapter_id: edit?.chapter_id,
    topic_id: edit?.topic_id,
    lesson_id: edit?.lesson_id,
  }
}
