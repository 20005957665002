<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="ASSIGNMENT"
      tooltip-title="ASSIGNMENT"
      :display-breadcrumb="true"
    />
    <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
    <NoRecordFound v-else-if="!isLoading && isEmpty(assignmentsList)" />
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH v-for="(head, index) in tableHeaders" :key="index">
            {{ head }}
          </TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(assignment, index) in assignmentsList" :key="index">
          <TD>
            <span
              class="text-primary-purple-600 cursor-pointer"
              @click.prevent="redirecToDetailPage(assignment.id)"
            >
              {{ assignment.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </span>
          </TD>
          <TD>
            {{ assignment.description || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD>
            {{ assignment.assignment_type.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD>
            {{ assignment.subject.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD>
            <BadgeDisplay
              :options="assignment.assignment_attachments"
              :profile="true"
              :is-object-concated="false"
              label="file_name"
            />
          </TD>
          <TD>
            <BadgeDisplay
              :options="assignment.assignment_web_links"
              :is-object-concated="false"
              label="title"
            />
          </TD>
          <TD>
            <TableAction
              :idx="index"
              :current-user="assignment"
              :action-list="actionList"
              @action="typeAction"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :key="forceRender"
      :record-limit="GENERAL_CONSTANTS.RECORD_LIMIT"
      :max-range="paginationCounts(assignmentCounts, GENERAL_CONSTANTS.RECORD_LIMIT)"
      @filterRecord="filterRecords"
    />
    <UIConfirmationModal
      v-if="confirmation"
      heading="DELETE_A"
      message="Are you sure you want to delete "
      button-text="Delete"
      :modal="confirmation"
      :name="currentSelectedAssignment?.title || ''"
      remaining-message="assignment ?"
      @cancel="toggleConfirmationModal"
      @confirm="deleteType"
    />
    <AssignmentsModal
      v-if="showModal"
      :modal="showModal"
      :edit="objectDeepCopy(currentSelectedAssignment)"
      @toggle="toggleAssignmentsModal"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue'

// Components
import NoRecordFound from '@src/components/BaseComponent/NoRecordFound.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import TableAction from '@components/TableAction.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import AssignmentsModal from '@src/router/views/assignments/modals/AddAndUpdateAssignmentModal.vue'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import BadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
// Own modules
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import {
  paginationCounts,
  paginationRangeHandler,
} from '@src/components/BaseComponent/pagination.util.js'
import { objectDeepCopy } from '@utils/generalUtil'

// Third Party
import isEmpty from 'lodash/isEmpty'

// Store
import { useStore } from 'vuex'
const store = useStore()

import { useRouter } from 'vue-router'
const router = useRouter()

// States
const isLoading = ref(false)
const showPagination = ref(false)
const currentSelectedAssignment = ref(null)
const assignmentsList = ref([])
const assignmentCounts = ref(0)
const tableHeaders = ref([
  'Title',
  'Description',
  'Assignment Type',
  'Subject',
  'Attachments',
  'Web Links',
  'Actions',
])
const actionList = ref([{ name: 'Edit' }, { name: 'Delete' }])
const confirmation = ref(false)
const forceRender = ref(0)

// Computed
const currentClassScope = computed(() => store.state.layout.currentClassScope)
const currentSectionScope = computed(() => store.state.layout.currentSectionScope)
const showModal = computed(() => store.state.layout.showModal)

// watchers

watch(
  () => currentClassScope.value,
  (classScope) => {
    if (classScope) filterRecords()
  },
)
watch(
  () => currentSectionScope.value,
  () => {
    filterRecords()
  },
)

// Mounted Hook
onMounted(() => {
  setRightbarData()
  filterRecords()
})

// Methods

const filterRecords = (range) => {
  isLoading.value = true
  let paginationPayload = paginationRangeHandler(range)

  getAssignments(paginationPayload)
    .then((response) => {
      assignmentsList.value = response?.data?.records
      assignmentCounts.value = response?.data?.meta?.total_records
      showPagination.value = assignmentCounts.value > GENERAL_CONSTANTS.RECORD_LIMIT
      setRightbarData(assignmentCounts.value)
    })
    .finally(() => {
      isLoading.value = false
    })
}

const typeAction = (action, currentType, idx) => {
  currentSelectedAssignment.value = currentType
  switch (action) {
    case 'Edit':
      toggleAssignmentsModal()
      break
    case 'Delete':
      toggleConfirmationModal()
      break
  }
}

const toggleAssignmentsModal = (payload) => {
  store.dispatch('layout/setShowModal', !showModal.value)
  if (!showModal.value) currentSelectedAssignment.value = null
  if (payload) filterRecords()
  if (payload === 'fetch') forceRender.value++
}

const setRightbarData = (count = 0) => {
  const rightBarContent = {
    header: {
      title: 'RB_A',
      buttons: [
        {
          title: 'RB_AA',
          classes: ['primary-button-right'],
          action: { name: 'layout/setShowModal', payload: {} },
        },
      ],
    },
    stats: [
      {
        title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
        categories: [
          {
            text: 'TOTAL_NO_ASSIGNMENTS',
            value: count,
            tooltipDescription: '',
          },
        ],
      },
    ],
  }
  store.dispatch('layout/setRightbarContent', rightBarContent)
}

const toggleConfirmationModal = () => {
  confirmation.value = !confirmation.value
  if (!confirmation.value) currentSelectedAssignment.value = null
}

const deleteType = () => {
  let id = currentSelectedAssignment.value.id
  deleteAssignments(id).then(() => {
    store.commit('toast/NEW', { message: 'Deleted Successfully', type: 'success' })
    toggleConfirmationModal()
    filterRecords()
    forceRender.value++
  })
}

const redirecToDetailPage = (id) => {
  router.push({ name: 'assignmens-detail-view', params: { id: id } })
}
// Store actions
const getAssignments = (payload) => store.dispatch('assignment/getAssignments', payload)
const deleteAssignments = (id) => store.dispatch('assignment/deleteAssignments', id)
</script>
