<template>
  <div>
    <div class="flex justify-between items-center">
      <Heading heading="Check Lists" class="text-center mb-4" />
      <button
        class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button px-2"
        @click.prevent="addNewCheckListItems"
      >
        Add New CheckList
      </button>
    </div>
    <div v-if="checkListItems.length" class="flex flex-col">
      <template v-for="(checkItem, index) in checkListItems" :key="index">
        <div class="flex flex-col">
          <div class="flex gap-2 items-center">
            <span class="text-text-color">{{ index + 1 }}:</span>
            <InputBox
              v-model="checkItem.title"
              type="text"
              :name="`TITLE ${index}`"
              title="Title"
              :is-not-translated="true"
              label="Title"
              placeholder="Title"
              class="flex-1 w-full"
              rules="required"
            />
            <button
              class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button px-2 bold"
              @click.prevent="addNestedCheckListItem(index)"
            >
              +
            </button>
            <span class="flex justify-end" @click="removeCheckListItem(index)">
              <icon
                class="icon cursor-pointer"
                color="text-color-danger"
                icon="crossCircle"
                height="20"
                width="20"
              />
            </span>
          </div>
          <div v-if="checkItem.assignment_checklist_items.length" class="flex flex-col gap-3 ml-10">
            <template
              v-for="(nestedCheckItem, idx) in checkItem.assignment_checklist_items"
              :key="idx"
            >
              <div class="w-2/4 flex items-center gap-2">
                <span>{{ intToRoman(idx + 1) }}:</span>
                <InputBox
                  :id="`NESTED_TITLE_${index}_${idx}`"
                  v-model="nestedCheckItem.title"
                  type="text"
                  :name="`NESTED_TITLE_${index}_${idx}`"
                  title="Title"
                  :is-not-translated="true"
                  label="Title"
                  placeholder="Title"
                  class="flex-1"
                  rules="required"
                />
                <span class="flex justify-end" @click="removeNestedCheckListItem(index, idx)">
                  <icon
                    class="icon cursor-pointer"
                    color="text-color-danger"
                    icon="crossCircle"
                    height="20"
                    width="20"
                  />
                </span>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'

// Components
import Heading from '@src/components/Heading.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import icon from '@src/components/icons/icon.vue'

// Own Module s
import { objectDeepCopy, intToRoman } from '@utils/generalUtil'

const props = defineProps({ modelValue: { default: () => [], type: Array } })
const emits = defineEmits(['update:modelValue'])

// States
let checkListItems = ref([])
let newItem = ref({ title: '', assignment_checklist_items: [] })

// Watch
watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue.length) checkListItems.value = newValue
    emits('update:modelValue', checkListItems.value)
  },
)

// Mounted
onMounted(() => {
  emits('update:modelValue', checkListItems.value)
})

// Methods
const addNewCheckListItems = () => {
  checkListItems.value.push(objectDeepCopy(newItem.value))
  emits('update:modelValue', checkListItems.value)
}

const removeCheckListItem = (index) => {
  checkListItems.value.splice(index, 1)
  emits('update:modelValue', checkListItems.value)
}

const removeNestedCheckListItem = (index, idx) => {
  checkListItems.value[index].assignment_checklist_items.splice(idx, 1)
  emits('update:modelValue', checkListItems.value)
}

const addNestedCheckListItem = (index) => {
  if (checkListItems.value[index].assignment_checklist_items.length < 5)
    checkListItems.value[index].assignment_checklist_items.push(objectDeepCopy({ title: '' }))
  emits('update:modelValue', checkListItems.value)
}
</script>
