<template>
  <div>
    <div class="flex justify-between items-center">
      <Heading heading="Assignment Attachments" class="text-center mb-4" />
      <div class="w-1/4">
        <UiInput
          id="input-type-file-assignment"
          type="file"
          class="border-none upload-file"
          rules="size:400"
          name="UPLOAD_ATTACHMENTS"
          title="UPLOAD_ATTACHMENTS"
          :hide-title="true"
          placeholder="Upload Attachments"
          @change="uploadAttachments"
        />
      </div>
    </div>
    <div class="flex">
      <template v-for="(attachment, index) in attachments" :key="index">
        <div class="flex mr-3 items-center gap-3">
          <span class="text-primary-green">{{ index + 1 }}:</span>
          <div class="w-20 truncate">
            {{ attachment.file_name }}
          </div>
          <icon
            class="icon cursor-pointer"
            color="text-color-danger"
            icon="crossCircle"
            height="20"
            width="20"
            @click.prevent="removeAttachment(index)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'

// Components
import Heading from '@src/components/Heading.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import icon from '@src/components/icons/icon.vue'
// Store
import { useStore } from 'vuex'

const store = useStore()
const props = defineProps({ modelValue: { default: () => [], type: Array } })
const emits = defineEmits(['update:modelValue'])

// States
let attachments = ref([])

// Watch
watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue.length) attachments.value = newValue
    emits('update:modelValue', attachments.value)
  },
)
watch(
  () => attachments.value,
  () => attachmentsHandler(),
)

// Mounted hook
onMounted(() => {
  attachmentsHandler()
})

// Methods
const uploadAttachments = (file) => {
  if (attachments.value.length < 5) {
    const form = new FormData()
    form.append('assignment_attachment', file)
    uploadAssignmentAttachments(form).then((response) => {
      let attachment = response.data
      attachments.value.push(attachment)
    })
  } else store.commit('toast/NEW', { message: 'Limit exceed', type: 'info' })
}
const attachmentsHandler = () => {
  emits('update:modelValue', attachments.value)
}
const removeAttachment = (index) => {
  attachments.value.splice(index, 1)
}
// Actions
const uploadAssignmentAttachments = (payload) =>
  store.dispatch('assignment/uploadAssignmentAttachments', payload)
</script>
