<template>
  <div>
    <div class="flex justify-between items-center">
      <Heading heading="Web Links" class="text-center mb-4" />
      <button
        class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button px-2"
        @click.prevent="addNewWebLink"
      >
        Add New WebLink
      </button>
    </div>
    <div v-if="webLinks.length" class="flex flex-col">
      <div
        v-for="(webLink, index) in webLinks"
        :key="index"
        class="flex flex-col sm:flex-row sm:items-center gap-4 lg:gap-5"
      >
        <InputBox
          v-model="webLink.title"
          type="text"
          :name="`WEB_TITLE + ${index}`"
          title="Web Title"
          label="Web Title"
          placeholder="Web Title"
          :is-not-translated="true"
          rules="required"
          class="flex-1 w-full"
        />
        <InputBox
          v-model="webLink.link"
          type="text"
          :name="`LINK + ${index}`"
          title="LINK"
          :is-not-translated="true"
          label="LINK"
          placeholder="LINK"
          rules="required"
          class="flex-1 w-full"
        />
        <span class="flex justify-end" @click="removeWeblink(index)">
          <icon
            class="icon cursor-pointer"
            color="text-color-danger"
            icon="crossCircle"
            height="20"
            width="20"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'

// Components
import Heading from '@src/components/Heading.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import icon from '@src/components/icons/icon.vue'

// Own Module s
import { objectDeepCopy } from '@utils/generalUtil'

const props = defineProps({ modelValue: { default: () => [], type: Array } })
const emits = defineEmits(['update:modelValue'])

// States
let webLinks = ref([])
let newItem = ref({ title: '', link: '' })

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue.length) webLinks.value = newValue
    emits('update:modelValue', webLinks.value)
  },
)

// Mounted
onMounted(() => {
  emits('update:modelValue', webLinks.value)
})

// Methods
const addNewWebLink = () => {
  webLinks.value.push(objectDeepCopy(newItem.value))
  emits('update:modelValue', webLinks.value)
}
const removeWeblink = (position) => {
  webLinks.value.splice(position, 1)
}
</script>
